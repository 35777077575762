var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.market)?_c('div',[_c('pp-card-body',{staticClass:"overflow-visible"},[_c('pp-simple-data',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('pp-tab-container',[_c('pp-tab',{attrs:{"tag":_vm.market.statusIs('active') ? 'router-link' : 'button',"to":{
              name: _vm.$route.name.includes('SingleAsset') ? 'AddSingleAsset' : 'AddDualAssets',
              params: _vm.$route.params,
            },"active":_vm.$route.matched.map(function (route) { return route.name; }).includes('AddLiquidity'),"data-test":"slider-add","disabled":_vm.market.statusIs('inactive')}},[_vm._v(" Add ")]),_c('pp-tab',{attrs:{"tag":"router-link","to":{
              name: _vm.$route.name.includes('SingleAsset')
                ? 'RemoveSingleAsset'
                : 'RemoveDualAssets',
              params: _vm.$route.params,
            },"active":_vm.$route.matched.map(function (route) { return route.name; }).includes('RemoveLiquidity'),"data-test":"slider-remove"}},[_vm._v(" Remove ")])],1)]},proxy:true},{key:"right",fn:function(){return [(_vm.market.liquidityTypes.includes('single'))?_c('pp-select',{attrs:{"data-test":"dual-single-asset","options":_vm.options,"btn-class":"border shadow-pendle min-w-40"},model:{value:(_vm.subRoute),callback:function ($$v) {_vm.subRoute=$$v},expression:"subRoute"}}):_vm._e()]},proxy:true}],null,false,3575147894)})],1),_c('router-view')],1):_c('pp-not-found',[_vm._v("No liquidity pool found for this token.")])}
var staticRenderFns = []

export { render, staticRenderFns }
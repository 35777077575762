<template>
  <div v-if="market">
    <pp-card-body class="overflow-visible">
      <pp-simple-data>
        <template #left>
          <pp-tab-container>
            <pp-tab
              :tag="market.statusIs('active') ? 'router-link' : 'button'"
              :to="{
                name: $route.name.includes('SingleAsset') ? 'AddSingleAsset' : 'AddDualAssets',
                params: $route.params,
              }"
              :active="$route.matched.map((route) => route.name).includes('AddLiquidity')"
              data-test="slider-add"
              :disabled="market.statusIs('inactive')"
            >
              Add
            </pp-tab>
            <pp-tab
              tag="router-link"
              :to="{
                name: $route.name.includes('SingleAsset')
                  ? 'RemoveSingleAsset'
                  : 'RemoveDualAssets',
                params: $route.params,
              }"
              :active="$route.matched.map((route) => route.name).includes('RemoveLiquidity')"
              data-test="slider-remove"
            >
              Remove
            </pp-tab>
          </pp-tab-container>
        </template>

        <template #right>
          <pp-select
            v-if="market.liquidityTypes.includes('single')"
            data-test="dual-single-asset"
            :options="options"
            v-model="subRoute"
            btn-class="border shadow-pendle min-w-40"
          ></pp-select>
        </template>
      </pp-simple-data>
    </pp-card-body>

    <router-view></router-view>
  </div>

  <pp-not-found v-else>No liquidity pool found for this token.</pp-not-found>
</template>

<script>
  import Vue from 'vue'
  import PendleAmmMarket from '@/domains/entities/PendleAmmMarket'
  import PendleMarket from '@/domains/entities/PendleMarket'

  export default Vue.extend({
    provide() {
      return {
        market: this.market,
      }
    },
    computed: {
      market() {
        return (
          PendleAmmMarket.query().findBy('address', this.$route.params.inputTokenAddress) ||
          PendleMarket.query().findBy('address', this.$route.params.inputTokenAddress)
        )
      },
      options() {
        return [
          {
            text: 'Dual Assets',
            value: 'dual-assets',
            disabled: false,
          },
          {
            text: 'Single Asset',
            value: 'single-asset',
            disabled: this.market.statusIs('inactive'),
          },
        ]
      },
      subRoute: {
        get() {
          return this.$route.path.split('/').pop()
        },
        set(val) {
          const currentRoute = this.$route.path.split('/')
          currentRoute.pop()
          this.$router.push([...currentRoute, val].join('/'))
        },
      },
    },
  })
</script>
